@import "bootstrap_3_3_6/bootstrap/_variables";

.hovereffect {
    width: 100%;
    height: 100%;
    float: left;
    /*overflow: hidden;*/
    position: relative;
    text-align: center;
    cursor: default;

    @media(max-width: 767px){
        margin-bottom: 20px;
        background-color: $gray-lighter;
    }
    @media(min-width: 768px){
        margin-bottom: 18px;
        background-color: $gray-light;
    }
    @media(min-width: 992px){
        margin-bottom: 12px;
    }
    @media(min-width: 1200px){
        margin-bottom: 14px;
    }
}

.hovereffect .anchor-wrapper{
    display: block;
    width: 100%;
    height: 100%;
}

.hovereffect .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    background-color: rgba(0,0,0,0.5);
}

.hovereffect img {
    display: block;
    position: relative;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    max-width: 100%;
    margin: 0 auto;
    @media(max-width: 767px){

    }
    @media(min-width: 992px){
        max-height: 319px;
    }
    @media(min-width: 1200px){
        max-height: 394px;
    }
}

.hovereffect h3 {
    text-align: center;
    /*opacity: 0;
    filter: alpha(opacity=0);*/
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    /*background-color: rgba(0,0,0, 0.5);*/
    /*background-color: $brand-primary;*/

    /*width: 100%;*/
    margin: 0px;
    padding: 8px;
    color:#fff;
    /*z-index: 2;*/

    @media(max-width: 767px){
        /*margin-top: -100px;
        margin-bottom: 100px;*/
        text-align: center;
        font-size: 1.0em;
    }
    @media(min-width: 768px){
        position: absolute;
        bottom: 0px;
        left: 0px;
        font-size: 1.0em;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.hovereffect h3.primary{
    background-color: $brand-primary;
}

.hovereffect h3.warning{
    background-color: $brand-warning;
}

.hovereffect h3.danger{
    background-color: $brand-danger;
}

.hovereffect h3.info{
    background-color: $brand-info;
}

.hovereffect h3.default{
    background-color: $gray;
}

.hovereffect span.info {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid #fff;
    background-color: transparent;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    padding: 10px 15px;
    position: absolute;
    bottom: 45%;
    letter-spacing: 0.3em;
    background-color: rgba(0,0,0, 0.4);
    @media(max-width: 767px){
        left: 40%;
    }
    @media(min-width: 768px){
        left: 31%;
    }
    @media(min-width: 992px){
        left: 19%;
    }
    @media(min-width: 1200px){
        left: 25%;
    }
}

.hovereffect span.lg-info{
    left: 40% !important;
}

.hovereffect span.md-info{
    left: 30% !important;
}

.hovereffect span.info:hover {
    box-shadow: 0 0 5px #fff;
}

.hovereffect:hover .overlay {
    opacity: 1;
    filter: alpha(opacity=100);
}

.hovereffect:hover h3,.hovereffect:hover span.info {
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-transform: translatey(0);
    -webkit-transform: translatey(0);
    transform: translatey(0);
}

.hovereffect:hover span.info {
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
}

.hovereffect-left{
    @media(min-width: 768px){
        height: 542px;
    }
    @media(min-width: 992px){
        height: 319px;
    }
    @media(min-width: 1200px){
        height: 394px;
    }
}

.hovereffect-mid-top{
    @media(min-width: 768px){
        height: 261px;
        margin-bottom: 20px;
    }
    @media(min-width: 992px){
        height: 154px;
        margin-bottom: 12px;
    }
    @media(min-width: 1200px){
        height: 184px;
        margin-bottom: 25px;
    }
}

.hovereffect-mid-bottom {
    @media(min-width: 768px){
        height: 261px;
    }
    @media(min-width: 992px){
        height: 154px;
    }
    @media(min-width: 1200px){
        height: 184px;
    }
}
